import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IFirebaseService } from '@nx-bundesliga/bundesliga-com/services/firebase';
import { combineLatest, Observable, of, OperatorFunction, take } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Competition, Language, MatchType } from '@nx-bundesliga/models';
import { getWorkingCompetition, getWorkingLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { BUNDESLIGA_SEASONS, LiveBlogMatch, Match } from '@nx-bundesliga/models';
import { DflDatalibraryService } from '@nx-bundesliga/shared/data-access/dfl-data-library';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';

@Injectable({
	providedIn: 'root'
})
export class MatchesService extends IFirebaseService {
	constructor(private tState: TransferState, private httpc: HttpClient, @Inject(PLATFORM_ID) private pId: Object, private config: ConfigService, private dataLibrary: DflDatalibraryService, private readonly competitionStore: Store<Competition>, private readonly languageStore: Store<Language>) {
		super(tState, httpc, pId, config);
	}

	private filterHiddenLiveBlogEntries(): OperatorFunction<LiveBlogMatch, LiveBlogMatch> {
		return map((match: LiveBlogMatch) => {
			if (!(match && match.liveBlogEntries)) {
				return match; // No need to filter if liveBlogEntries is undefined
			}
			return {
				...match,
				liveBlogEntries: Object.keys(match.liveBlogEntries).reduce(function (acc, cur) {
					if (!match.liveBlogEntries[cur]?.hidden === true) {
						acc[cur] = match.liveBlogEntries[cur];
					}
					return acc;
				}, {})
			};
		});
	}

	/**
	 * get Basic Match infos By Matchday
	 * @param lang
	 * @param matchday
	 * @param seasonId
	 * @param competitionId
	 */

	public getBasicMatchinfosByMatchday(lang: string, matchday: number, seasonId: string, competitionId: string): Observable<Match[]> {
		const listPathBase = ['', 'all', competitionId, 'seasons', seasonId, 'matches'];
		const listPath = listPathBase.join('/');

		return this._getDataFromFirebaseWithFilter(listPath, 'matchday', matchday, 99).pipe(take(1));
	}

	/**
	 * get Basic Match infos By Matchday
	 * @param lang
	 * @param competitionsList
	 * @param seasonId
	 */

	public getFlatBasicMatchinfosByCompetions(competitionsList: string[], seasonId = BUNDESLIGA_SEASONS.CURRENT.toString()): Observable<Match[]> {
		const competitionFirebasePaths: string[] = [];
		let listPathBase = [];
		let i = 0;

		for (const competitionId of competitionsList) {
			// receive data from firebase
			listPathBase = ['', 'all', competitionId, 'seasons', seasonId, 'matches'];
			competitionFirebasePaths[i] = listPathBase.join('/');
			i++;
		}
		return combineLatest(...competitionFirebasePaths.map((competitionFirebasePath) => this._getDataFromFirebase(competitionFirebasePath))).pipe(
			map((matches) => {
				const matchObjects = [];
				for (let i = 0; i < matches.length; ++i) {
					for (const [matchId, matchObject] of Object.entries(matches[i])) {
						const isMatch = matchId.match(/DFL\-MAT\-\w{6}/g); /*transferstate is part of the array :/ */
						if (isMatch) {
							matchObjects.push(matchObject);
						}
					}
				}
				return matchObjects;
			})
		);
	}

	/**
	 * get Basic Match infos By ClubId
	 * @param clubId
	 * @param lang
	 * @param seasonId
	 */

	public getAllMatchesByClubId(clubId: string, lang: string, seasonId = BUNDESLIGA_SEASONS.CURRENT.toString()): Observable<Match[]> {
		return this.competitionStore.pipe(
			select(getWorkingCompetition),
			map((competition: Competition) => competition.id),
			switchMap((competitionId: string) => {
				const listPathBase = ['', 'all', competitionId, 'seasons', seasonId, 'matches'];
				const listPath = listPathBase.join('/');
				const clubMatchesHome = this._getDataFromFirebaseWithFilter(listPath, 'teams/home/dflDatalibraryClubId', clubId, 99);
				const clubMatchesAway = this._getDataFromFirebaseWithFilter(listPath, 'teams/away/dflDatalibraryClubId', clubId, 99);
				return combineLatest([clubMatchesHome, clubMatchesAway]).pipe(map(([home, away]) => [...home, ...away]));
			})
		);
	}

	/**
	 * Returns an observable for the entire firebase matchday/match node, including all clubData, liveEvents
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchdayId
	 * @param matchId
	 *
	 * @webviewOnly
	 */

	public getLiveBlogMatch(competitionId: string, seasonId: string, matchdayId: string, matchId: string): Observable<LiveBlogMatch> {
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			map((language: Language) => (language.code === 'jp' ? 'en' : language.code)),
			switchMap((lang: string) => {
				const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays', matchdayId, matchId].join('/');
				return this._getDataFromFirebase(path);
			}),
			this.filterHiddenLiveBlogEntries()
		);
	}

	public getMatch(competitionId: string, seasonId: string, matchdayId: string, matchId: string): Observable<Match> {
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			map((language: Language) => (language.code === 'jp' ? 'en' : language.code)),
			switchMap((lang: string) => {
				const path = ['', 'all', competitionId, 'seasons', seasonId, 'matches', matchId].join('/');
				return this._getDataFromFirebase(path);
			})
		);
	}

	/**
	 * Returns an observable for the entire firebase matchday node, including all matches for that day with all its clubData, liveEvents
	 *
	 * @param competitionId
	 * @param seasonId
	 * @param matchdayId
	 *
	 */

	public getAllMatchesOfMatchday(competitionId: string, seasonId: string, matchdayId: string): Observable<any> {
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			map((language: Language) => (language.code === 'jp' ? 'en' : language.code)),
			switchMap((lang: string) => {
				const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays', matchdayId].join('/');
				return this._getDataFromFirebase(path);
			})
		);
	}

	/**
	 * getSupercupMatchBySeason
	 * @param seasonName
	 */

	public getSupercupMatchBySeason(seasonName: string): Observable<LiveBlogMatch> {
		let seasonId = BUNDESLIGA_SEASONS.CURRENT.toString();
		if (seasonName !== '') {
			seasonId = this.dataLibrary.getSeasonIdBySeasonName(seasonName);
		}
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			map((language: Language) => (language.code === 'jp' ? 'en' : language.code)),
			switchMap((lang: string) => {
				const path = ['', lang, 'DFL-COM-000003', 'seasons', seasonId, 'matchdays'].join('/');
				return this._getDataFromFirebase(path).pipe(
					map((matches) => {
						/* supercup has alwasy one matchday with one match */
						const matchDayMatch = matches[Object.keys(matches)[0]]; /* pick the first matchday */
						return matchDayMatch[Object.keys(matchDayMatch)[0]] as LiveBlogMatch; /* pick the first match */
					})
				);
			}),
			this.filterHiddenLiveBlogEntries()
		);
	}

	/**
	 * getRelegationMatchBySlug
	 * @param matchSlug
	 * @param seasonName
	 * @param competitionId
	 */

	public getRelegationMatchBySlug(matchSlug: string, seasonName: string, competitionId: string): Observable<LiveBlogMatch> {
		let seasonId = BUNDESLIGA_SEASONS.CURRENT.toString();
		if (seasonName !== '') {
			seasonId = this.dataLibrary.getSeasonIdBySeasonName(seasonName);
		}

		if (competitionId === 'DFL-COM-000001') {
			competitionId = 'DFL-COM-000004';
		} else {
			competitionId = 'DFL-COM-000005';
		}
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			map((language: Language) => (language.code === 'jp' ? 'en' : language.code)),
			switchMap((lang: string) => {
				const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays'].join('/');
				return this._getDataFromFirebase(path).pipe(
					map((matchdayMatches) => {
						for (const [matchDayId, matchDayMatches] of Object.entries(matchdayMatches)) {
							for (const [matchId, matchDayMatch] of Object.entries(matchDayMatches)) {
								if (matchDayMatch['slugs']['slugLong'] === matchSlug) {
									return matchDayMatch;
								}
							}
						}
						return false;
					})
				);
			}),
			this.filterHiddenLiveBlogEntries()
		);
	}

	/**
	 * getMatchByMatchSlug
	 * @param matchSlug
	 * @param matchday
	 * @param seasonName
	 * @param competitionId
	 */

	public getMatchByMatchSlug(matchSlug: string, matchday: any, seasonName: string, competitionId: string): Observable<LiveBlogMatch> {
		return this.languageStore.pipe(
			select(getWorkingLanguage),
			map((language: Language) => (language.code === 'jp' ? 'en' : language.code)),
			switchMap((lang: string) => {
				if ((this.isValidMatchSlug(matchSlug) && matchday >= 0 && matchday <= 34) || (this.isValidMatchSlug(matchSlug) && this.isValidMatchType(matchday))) {
					/* normal matchday number */
					let seasonId = BUNDESLIGA_SEASONS.CURRENT.toString();
					if (seasonName !== '') {
						seasonId = this.dataLibrary.getSeasonIdBySeasonName(seasonName);
					}
					const dflDataLibraryMatchdayId = this.dataLibrary.getMatchdayIdByNumber(matchday, seasonId);
					const path = ['', lang, competitionId, 'seasons', seasonId, 'matchdays', dflDataLibraryMatchdayId].join('/');
					return this._getDataFromFirebaseWithFilter(path, 'slugs/slugLong', matchSlug, 1).pipe(
						map((matches) => {
							if (matches.length <= 1) {
								// if just one match return direcly
								return matches[0] as LiveBlogMatch;
							} else {
								// matchay is numeric its either normal bundesliga or groupphase
								if (matchday >= 0 && matchday <= 34) {
									return matches[0] as LiveBlogMatch;
								} else {
									return matches[1] as LiveBlogMatch;
								}
							}
						})
					);
				} else if (this.isSupercup(matchday)) {
					return this.getSupercupMatchBySeason(seasonName);
				} else if (this.isValidMatchSlug(matchSlug) && this.isRelegation(matchday)) {
					return this.getRelegationMatchBySlug(matchSlug, seasonName, competitionId);
				} else {
					return of(null as LiveBlogMatch);
				}
			}),
			this.filterHiddenLiveBlogEntries()
		);
	}

	public isValidMatchSlug(matchSlug): boolean {
		const slugSplitted = matchSlug.split('-vs-');
		const regexpClubname = new RegExp(/^[a-zßäöü\d-']*$/, '');
		const isValidHomeClub = regexpClubname.test(slugSplitted[0]);
		const isValidAwayClub = regexpClubname.test(slugSplitted[1]);
		return isValidHomeClub && isValidAwayClub;
	}

	public isValidMatchType(matchTypeParsed: string): boolean {
		if (matchTypeParsed && matchTypeParsed !== '') {
			const allowedMatchTypes = ['group-stage', 'round-of-16', 'quarter-finals', 'semi-finals', 'final', 'achtelfinale', 'viertelfinale', 'halbfinale', 'finale'];
			return allowedMatchTypes.includes(matchTypeParsed);
		}
		return false;
	}

	/**
	 * isValidGroupname
	 * @param groupName for example G
	 */
	public isValidGroupname(groupName: string): boolean {
		if (groupName && groupName !== '') {
			const allowedGroupNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
			return allowedGroupNames.includes(groupName.toUpperCase());
		}
		return false;
	}

	/**
	 * mapMatchTypefromUrl
	 * @param matchType for round-of-16
	 */
	public mapMatchTypefromUrl(matchType: string): MatchType {
		if (matchType.includes('group')) {
			return 'Group Stage';
		}
		if (matchType.includes('round-of-16')) {
			return 'Round Of 16';
		}
		if (matchType.includes('quarter-finals')) {
			return 'Quarter-finals';
		}
		if (matchType.includes('semi-finals')) {
			return 'Semi-finals';
		}
		if (matchType.includes('final')) {
			return 'Final';
		}
		return 'Group Stage';
	}

	/**
	 * isRelegation
	 * @param matchdayFromUrl
	 */
	public isRelegation(matchdayFromUrl: string): boolean {
		if (!matchdayFromUrl || matchdayFromUrl === '') {
			return false;
		}

		return matchdayFromUrl.toLowerCase() === 'relegation' || matchdayFromUrl.toLowerCase() === 'descenso';
	}

	/**
	 * isSupercup
	 * @param matchdayFromUrl
	 */
	public isSupercup(matchdayFromUrl: string): boolean {
		if (!matchdayFromUrl || matchdayFromUrl === '') {
			return false;
		}

		return matchdayFromUrl.toLowerCase() === 'supercup' || matchdayFromUrl.toLowerCase() === 'supercopa' || matchdayFromUrl.toLowerCase() === 'super-coupe';
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isLiveMatch(match: Match): boolean {
		/* @fixme with correct state and remove PRE_EXTRA */
		return match.matchStatus !== 'PRE_MATCH' && match.matchStatus !== 'FINAL_WHISTLE' && match.matchStatus !== 'PRE_EXTRA' && match.dateQuality !== 'ABANDONED' && match.dateQuality !== 'AWARDED_TO' && match.dateQuality !== 'DECLARED_VOID' && match.dateQuality !== 'CANCELED';
	}

	/**
	 * checks if match was postponed
	 * @param match
	 */
	private isPostponedMatch(match: any): boolean {
		return match.dateQuality && match.dateQuality === 'POSTPONED';
	}

	/**
	 * a postponed match has no plannedKickoff
	 * once postponed, value stays but plannedKickoff may be set later
	 * plannedKickOff must always be in future
	 * @param match
	 */
	public isPostponedMatchWithNoOrPastPlannedKickoff(match: Match): boolean {
		const isPostponedPreMatch = this.isPostponedMatch(match) && match.matchStatus === 'PRE_MATCH';

		if (this.isScheduled(match)) {
			const isPlannedKickOffInPast = new Date(match.plannedKickOff).getTime() <= new Date().getTime();
			return isPostponedPreMatch && this.isScheduled(match) && isPlannedKickOffInPast;
		} else return isPostponedPreMatch && !this.isScheduled(match);
	}

	public isAwardedToMatch(match: Match): boolean {
		return match.dateQuality && match.dateQuality === 'AWARDED_TO';
	}

	public isRescheduledMatch(match: Match): boolean {
		return match.matchStatus && match.matchStatus === 'PRE_MATCH' && match.dateQuality && match.dateQuality === 'RESCHEDULED';
	}

	public isAbandonedMatch(match: Match): boolean {
		return match.dateQuality && match.dateQuality === 'ABANDONED';
	}

	public isSeasonCanceledMatch(match: Match): boolean {
		return match.dateQuality && (match.dateQuality === 'CANCELED' || match.dateQuality === 'SEASON_CANCELED');
	}
	public isDeclaredVoidMatch(match: Match): boolean {
		return match.dateQuality && match.dateQuality === 'DECLARED_VOID';
	}

	/**
	 * checks if match has live state
	 * @param match
	 */
	public isPenalty(match: Match): boolean {
		return match['matchStatus'] === 'PRE_PENALTY' || match['matchStatus'] === 'PENALTY';
	}

	/**
	 * checks if match has overtime state
	 * @param match
	 */
	public isOverTimeLiveMatch(match: Match): boolean {
		return (
			(match.dflDatalibraryCompetitionId === 'DFL-COM-000004' || match.dflDatalibraryCompetitionId === 'DFL-COM-000005') &&
			(match['matchStatus'] === 'PRE_EXTRA' || match['matchStatus'] === 'FIRST_HALF_EXTRA' || match['matchStatus'] === 'HALF_EXTRA' || match['matchStatus'] === 'SECOND_HALF_EXTRA' || match['matchStatus'] === 'PRE_PENALTY' || match['matchStatus'] === 'PENALTY')
		);
	}

	/**
	 * checks if match state is pre match
	 * @param match
	 */
	public matchIsPreMatchState(match: LiveBlogMatch | Match): boolean {
		return match && match.matchStatus.startsWith('PRE_');
	}

	/**
	 * getMatchCenterRouterLink
	 * @param match
	 * @param lang
	 */
	public getMatchCenterRouterLink(match: Match, lang = 'en'): string[] {
		const dflDatalibraryCompetitionId = match.dflDatalibraryCompetitionId;
		const dflDatalibrarySeasonId = match.dflDatalibrarySeasonId;

		const urlSeason = this.dataLibrary.getSeasonNameById(dflDatalibrarySeasonId, true);
		let urlCompetition = 'bundesliga'; /* default for BL1, Supercup & REL1*/
		let urlMatchday: string | number = match.matchday; /* default for BL1, BL2*/
		if (dflDatalibraryCompetitionId === 'DFL-COM-000002' || dflDatalibraryCompetitionId === 'DFL-COM-000005') {
			urlCompetition = '2bundesliga';
		} else if (dflDatalibraryCompetitionId === 'DFL-COM-J0002E') {
			urlCompetition = 'euro';
		}
		if (dflDatalibraryCompetitionId === 'DFL-COM-000004' || dflDatalibraryCompetitionId === 'DFL-COM-000005') {
			urlMatchday = 'route-relegation';
		} else if (dflDatalibraryCompetitionId === 'DFL-COM-000003') {
			urlMatchday = 'route-supercup';
		} else if (dflDatalibraryCompetitionId === 'DFL-COM-J0002E') {
			switch (match?.matchType) {
				case 'Round Of 16':
					urlMatchday = `route-matchday-round-of-16`;
					break;
				case 'Quarter-finals':
					urlMatchday = `route-matchday-quarter-final`;
					break;
				case 'Semi-finals':
					urlMatchday = `route-matchday-semi-final`;
					break;
				case 'Final':
					urlMatchday = `route-matchday-final`;
					break;
			}
		}
		return ['/', lang, urlCompetition, 'route-matchday', urlSeason, urlMatchday.toString(), match.slugs?.slugLong];
	}

	public sortMatchesByKickoffDate(matches: Match[]): Match[] {
		return matches.sort((a, b) => {
			const aprop = a.hasOwnProperty('plannedKickOff') ? a.plannedKickOff : a.matchdayRange.end;
			const bprop = b.hasOwnProperty('plannedKickOff') ? b.plannedKickOff : b.matchdayRange.end;
			if (aprop > bprop) {
				return 1;
			} else if (bprop > aprop) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	public isScheduled(match: Match): boolean {
		return match.plannedKickOff && match.plannedKickOff !== '' ? true : false;
	}
}
