import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, makeEnvironmentProviders, NgModule, TransferState } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { DateFnsAdapter, DateFnsModule } from '@angular/material-date-fns-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader } from '@ngx-translate/core';
import { CommonsModule } from '@nx-bundesliga/bundesliga-com/components/commons';
import { AnalyticsService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { APP_ENVIRONMENT, configFactory, CoreModule, metaFactory } from '@nx-bundesliga/bundesliga-com/framework/core';
import { factoryTranslateLoader, I18NModule } from '@nx-bundesliga/bundesliga-com/framework/i18n';
import { MetaLoader } from '@nx-bundesliga/bundesliga-com/framework/meta';
import { AppstoreModule } from '@nx-bundesliga/bundesliga-com/framework/store';
import { CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';
import { ThemingService } from '@nx-bundesliga/bundesliga-com/services/theming';
import { CORE_ENVIRONMENT } from '@nx-bundesliga/bundesmaster/core';
import { EnvironmentConfig, provideEnvironmentConfig } from '@nx-bundesliga/shared/config/environment-config';
import { ConfigLoader, ConfigService, initializerFactory } from '@nx-bundesliga/shared/forked/ngx-config';
import { AngularSplitModule } from 'angular-split';
import { de } from 'date-fns/locale';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { LivestreamService } from './components/molecules/livestream/livestream.service';
import { ProfileModule } from './components/molecules/profile/profile.module';
import { ThemeSwitcherModule } from './components/molecules/theme-switcher/theme-switcher.module';
import { CreateCustomMatchModule } from './components/pages/create-custom-match/create-custom-match.module';
import { CustomMatchDetailPageModule } from './components/pages/custom-match-detail-page/custom-match-detail-page.module';
import { CustomMatchOverviewPageModule } from './components/pages/custom-match-overview-page/custom-match-overview-page.module';
import { HomePageModule } from './components/pages/home/home.module';
import { LivestreamManagerModule } from './components/pages/livestream-manager/livestream-manager.module';
import { LoginModule } from './components/pages/login/login.module';
import { LogoutModule } from './components/pages/logout/logout.module';
import { MatchDetailPageModule } from './components/pages/match-detail-page/match-detail-page.module';
import { MatchOverviewPageModule } from './components/pages/match-overview-page/match-overview-page.module';
import { ToolsPageModule } from './components/pages/tools/tools.module';
import { WelcomePageModule } from './components/pages/welcome/welcome.module';
import { msalConfig, msalGuardConfig, msalInterceptorConfig } from './msal-configuration';
import { AnalyticsServiceStub } from './services/analytics-stub/analytics.service';
import { ClubService } from './services/club/club.service';
import { CookieConsentServiceStub } from './services/cookie-consent-stub/cookie-consent.service';
import { DflDatalibraryService } from './services/dfldatalibrary/dflDatalibrary.service';
import { DflDatalibraryMatchService } from './services/dfldatalibrary/dflDatalibraryMatch.service';
import { DflDatalibraryMatchdayService } from './services/dfldatalibrary/dflDatalibraryMatchday.service';
import { DflDatalibrarySeasonService } from './services/dfldatalibrary/dflDatalibrarySeason.service';
import { IsTypingService } from './services/liveticker-backend/is-typing.service';
import { LivetickerService } from './services/liveticker/liveticker.service';
import { LoadingService } from './services/loading/loading.service';
import { MatchesService } from './services/matches/matches.service';
import { PersonService } from './services/person/person.service';
import { TributeOptionsService } from './services/tribute-options/tribute-options.service';
import { UserService } from './services/user/user.service';

// for AoT compilation
// tslint:disable-next-line
/*export function configFactory(http: HttpClient): ConfigLoader {
	const localConfigLoader = new ConfigStaticLoader(environment); // API ENDPOINT (local variable)

	return new ConfigMergeLoader([localConfigLoader]).next((res: any) => new ConfigHttpLoader(http, res.endpoints.config.uri, './assets/config/firebase-config-fallback.json')); // SERIES EXECUTION
}*/

const isIframe = window !== window.parent && !window.opener;

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		RouterModule.forRoot(appRoutes, {
			useHash: false,
			// Don't perform initial navigation in iframes
			initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
			// relativeLinkResolution: 'legacy'
		}),
		MsalModule.forRoot(new PublicClientApplication(msalConfig), msalGuardConfig, msalInterceptorConfig),
		CoreModule.forRoot([
			{
				provide: APP_ENVIRONMENT,
				useValue: environment
			},
			{
				provide: ConfigLoader,
				useFactory: configFactory,
				deps: [HttpClient, APP_ENVIRONMENT]
			},
			{
				provide: MetaLoader,
				useFactory: metaFactory,
				deps: [ConfigService]
			},
			{
				provide: APP_INITIALIZER,
				useFactory: initializerFactory,
				deps: [ConfigService],
				multi: true
			}
		]),
		AppstoreModule,
		StoreDevtoolsModule.instrument({ maxAge: 100, connectInZone: true }),
		CommonsModule,
		BrowserAnimationsModule,
		I18NModule.forRoot([
			{
				provide: TranslateLoader,
				useFactory: factoryTranslateLoader,
				deps: [HttpClient, TransferState]
			}
		]),
		//MsalModule,
		MsalModule.forRoot(new PublicClientApplication(msalConfig), msalGuardConfig, msalInterceptorConfig),
		NgxTippyModule,
		OverlayscrollbarsModule,
		// AngularFireModule.initializeApp(environment.firebase),
		AngularFireDatabaseModule,
		DateFnsModule,
		HomePageModule,
		LoginModule,
		LogoutModule,
		MatchOverviewPageModule,
		MatchDetailPageModule,
		CustomMatchOverviewPageModule,
		CustomMatchDetailPageModule,
		CreateCustomMatchModule,
		ToolsPageModule,
		WelcomePageModule,
		LivestreamManagerModule,
		AngularSplitModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-center'
		}),
		MatProgressBarModule,
		MatToolbarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatSlideToggleModule,
		ProfileModule,
		MatChipsModule,
		MatIconModule,
		MatRippleModule,
		MatSidenavModule,
		ThemeSwitcherModule
	],
	providers: [
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideDatabase(() => getDatabase()),
		provideEnvironmentConfig(environment),
		makeEnvironmentProviders([
			{
				provide: EnvironmentConfig,
				useValue: environment
			}
		]),
		{
			provide: CORE_ENVIRONMENT,
			useValue: environment
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: DateAdapter,
			useClass: DateFnsAdapter,
			deps: [MAT_DATE_LOCALE]
		},
		{
			provide: MAT_DATE_LOCALE,
			useValue: de
		},
		{
			provide: AnalyticsService,
			useClass: AnalyticsServiceStub
		},
		{
			provide: CookieConsentService,
			useClass: CookieConsentServiceStub
		},
		DflDatalibraryService,
		DflDatalibrarySeasonService,
		DflDatalibraryMatchdayService,
		DflDatalibraryMatchService,
		LivetickerService,
		IsTypingService,
		MatchesService,
		LoadingService,
		ClubService,
		ThemingService,
		LivestreamService,
		PersonService,
		TributeOptionsService,
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		UserService
	]
})
export class AppModule {}
